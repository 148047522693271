import React, { useContext, useEffect } from 'react';
import { AppBar, Toolbar, Box, Typography, Tooltip, Avatar, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { CONTENT } from '../scripts/i18n';
import APP_CONSTANTS from '../scripts/constants';
import eventBus from '../scripts/event-bus';
import { buildInitialsForPicture } from '../scripts/helpers';
import { UserContext } from '../contexts/UserContext';
import orgLogoImg from '../../public/favicon.png';
import { logoutWithWorkOS } from '../scripts/apis/authentication';
import { LocalStorage } from '../scripts/LocalStorage';
import HeaderBar from './Headerbar';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: '#ffffff',
  boxShadow: 'none',
  position: 'fixed',
  top: 0,
  width: '100%',
  margin: 'auto',
}));

const LogoContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const Logo = styled('img')({
  height: '40px',
  cursor: 'pointer',
});

interface TopNavBarProps {
  onProfileClick?: () => void;
}

const TopNavBar: React.FC<TopNavBarProps> = ({ onProfileClick }): React.JSX.Element => {
  const navigate = useNavigate();

  const { userDetails } = useContext(UserContext);

  const handleLogout = (): void => {
    eventBus.dispatch(APP_CONSTANTS.EVENTS.POPUP_EVENT.RENDER, {
      open: true,
      title: CONTENT.SIDE_NAV_BAR.LOGOUT_CONTENT.TITLE,
      content: CONTENT.SIDE_NAV_BAR.LOGOUT_CONTENT.CONFIRMATION_MESSAGE,
      acceptEvent: APP_CONSTANTS.EVENTS.POPUP_EVENT.RESULT,
      acceptBtn: CONTENT.SIDE_NAV_BAR.LOGOUT_CONTENT.ACCEPT,
      rejectBtn: CONTENT.SIDE_NAV_BAR.LOGOUT_CONTENT.CANCEL,
    });
  };

  useEffect((): void => 
    {
        eventBus.on(APP_CONSTANTS.EVENTS.POPUP_EVENT.RESULT, async (data: any): Promise<void> => 
        {
            if (data.result) 
            {
                await logoutWithWorkOS();
                LocalStorage.removeAll();
                navigate('/login');
            }
        });
    }, []);


  const DropdownMenu = (): React.JSX.Element => {
    return (
      <div className='dropdown-menu-container'>
        <Dropdown.Item onClick={(): void => {
          navigate('/settings/general');
        }}>
          <Box className="user-account-menu-container">
            {userDetails && userDetails?.additionalInfo && userDetails?.additionalInfo?.image ? (
              <Avatar className="user-picture-menu-avatar" src={userDetails.additionalInfo.image}></Avatar>
            ) : (
              <Avatar className="user-empty-avatar">
                {buildInitialsForPicture(userDetails?.email || '', userDetails?.firstName || '', userDetails?.lastName || '')}
              </Avatar>
            )}
            <Tooltip placement="top-end" disableInteractive title={
              <Stack direction={'column'}>
                <Typography variant="subtitle2">{userDetails?.firstName + ' ' + userDetails?.lastName}</Typography>
                <Typography variant="subtitle2">{userDetails?.email}</Typography>
              </Stack>
            }>
              <Box className="user-name-email-container">
                <Typography className="user-name">{userDetails?.firstName + ' ' + userDetails?.lastName}</Typography>
                <Typography className="user-email">{userDetails?.email}</Typography>
              </Box>
            </Tooltip>
          </Box>
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={handleLogout}>
          <Box className="logout-container">
            <FontAwesomeIcon className="logout-icon" icon={['fal', 'arrow-right-from-bracket']} />
            <Typography className="logout-text">{'Logout'}</Typography>
          </Box>
        </Dropdown.Item>
      </div>
    );
  };

  return (
    <div className='top-nav-bar-container'>
      <StyledAppBar>
        <Toolbar sx={{ justifyContent: 'space-between', width: 'calc(100% - 224px)', margin: 'auto', paddingLeft: '16px', paddingRight: '16px' }}>
          <LogoContainer onClick={(): void => navigate('/events')} style={{cursor: 'pointer'}}>
            <Avatar className="org-img" src={orgLogoImg}></Avatar>
            <HeaderBar title={`Hello ${userDetails?.firstName ?? ''}`} />
        </LogoContainer>

        {<Box className="account-dropdown-btn">
            <DropdownButton
                title={
                    <Box className="account-drop-down-title-container">
                        {userDetails && userDetails?.additionalInfo &&  userDetails?.additionalInfo?.image !== null || '' ? <Avatar className="user-picture-menu-avatar" src={userDetails?.additionalInfo?.image}></Avatar> : <Avatar className="menu-icon-user-empty-avatar">
                            {(userDetails?.email || userDetails?.firstName || userDetails?.lastName) &&
                                buildInitialsForPicture(userDetails?.email || '', userDetails?.firstName || '', userDetails?.lastName || '')
                            }
                        </Avatar>}
                    </Box>
                }>
                <DropdownMenu />
            </DropdownButton>
            </Box>}
        </Toolbar>
      </StyledAppBar>
    </div>
  );
};

export default TopNavBar;