import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useTablePagination = ():{pageSize: number;
  updatePageSize: (value: number|string) => void;
  currentPage: number;
  updateCurrentPage: (value: number|string) => void;} => 
{
    const [pageSize, setPageSize] = useState(25);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageRefresh, setPageRefresh] = useState(false);

    const currentLocation = useLocation();

    useEffect(():void => 
    {
        setPageRefresh(true);
    }, [currentLocation.pathname]);

    useEffect(():void => 
    {
        if (pageRefresh) 
        {
            setCurrentPage(1);
            setPageSize(pageSize);
            setPageRefresh(false);
        }
    }, [pageRefresh]);

    const updatePageSize = (value):void => 
    {
        setPageSize(value);
    };

    const updateCurrentPage = (value):void => 
    {
        setCurrentPage(value);
    };

    return {
        pageSize, updatePageSize, currentPage, updateCurrentPage 
    };
};

