import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Popover } from "@mui/material";
import { useState } from "react";

export const TableActions: React.FC<{ actions: { title: string; onClick: () => void; className?: string }[] }> = ({ actions }): React.JSX.Element => 
{
    const [anchorEl, setAnchorEl] = useState<any>(null);

    const handleOpenDropdown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => 
    {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
    };

    const handleCloseDropdown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => 
    {
        setAnchorEl(null);
        event.stopPropagation();
    };

    return (
        <div>
            <IconButton className="actions-column-button" onClick={handleOpenDropdown}>
                <FontAwesomeIcon icon={['fal', 'ellipsis-vertical']} />
            </IconButton>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleCloseDropdown}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div id="dropDownBlock">
                    {
                        actions && actions?.length > 0 && actions?.map((item) => {
                            const className = item.className ? `dropDownMenu ${item.className}` : 'dropDownMenu';
                            return (
                                <div key={item.title} onClick={(event) => { item.onClick(); handleCloseDropdown(event) }} className={className}>
                                    {item.title}
                                </div>
                            )
                        })
                    }
                </div>
            </Popover>
        </div>
    );
};