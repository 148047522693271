import React, { useEffect, useState } from 'react';
import { Box, FormControlLabel, Stack, Tooltip, Typography, createFilterOptions } from '@mui/material';
import { Form } from 'react-bootstrap';
import { CONTENT } from '../../../scripts/i18n';
import moment from 'moment-timezone';
import { addEvent } from '../../../redux/events/Events';
import { useDispatch, useSelector } from 'react-redux';
import APP_CONSTANTS from '../../../scripts/constants';
import { createEvent, updateEvent } from '../../../scripts/apis/events';
import eventBus from '../../../scripts/event-bus';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LocalStorage } from '../../../scripts/LocalStorage';
import { CloseIconComponent, FormControlComponent, FormLabelComponent, TimezoneSelectComponent } from '../../../common/FormComponents/ReusableFormComponents';
import { CustomSwitch } from '../../../common/StyledComponents/Switch.styled';
import { getFormattedCurrentTimezone, uniqueTimezoneArr } from '../timezoneGenerateFunction';
import { ICreateEventProps, IEventReduxValues, IEventsDispatch } from '../interfaces/create-events_interface';
import { CustomButton } from '../../../common/FormComponents/Buttons';
import './styles.scss';
import DateTimePicker from '../../../common/FormComponents/DateTimePicker';
import { combineDateTime, transformTitleToLink } from '../../../scripts/helpers';
import toast from 'react-hot-toast';
import { UserModeration } from '../../../pages/Events/enum/event.enum';

const CreateEvent= (props: ICreateEventProps): React.JSX.Element => 
{

    const eventData = props.eventData;
    const filter = createFilterOptions();

    const [eventTitleLength, setEventTitleLength] = useState(0);
    const [spinner, setSpinner] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentTimezone =  getFormattedCurrentTimezone();


    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => 
    {
        return state.events.value;
    });

    const orgLink = LocalStorage.get('@Org')?.link;

    const csrfTokenData = useSelector((state): string => 
    {
        return state['csrfTokenValue'].value.csrfToken; 
    });

    const handleTimeZoneChange = (event: React.SyntheticEvent, newValue: string): void => 
    {

        formik.setFieldValue('timezone', newValue);

        if (newValue) 
        {
            const timezoneForSelectedValue = _.find(uniqueTimezoneArr, function (item: {name: string; value: string}) : boolean
            {
                return item.name === newValue;
            }).value;

            dispatch(addEvent({
                timezoneDisplayName: newValue
            }));

            dispatch(addEvent({
                timeZone: timezoneForSelectedValue ? timezoneForSelectedValue : ''
            }));
        }
    };

    const handleDrawerClose = (): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, {
            open: false,
        });

        eventBus.dispatch('selected-row-id', null);

        if (props?.routeFromId) 
        {
            navigate('/events');
        }
    };

    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Title is required').max(100, 'Event Title length exceeded'),
        link: Yup.string().matches(/^[a-zA-Z0-9_-]+$/, 'Enter valid Public URL').required('Public URL is required'),
        timezone: Yup.string().required('Timezone is required'),
        userModeration: Yup.number(),
        startDate: Yup.string().required('Start Date is required'),
        endDate: Yup.string().required('End Date is required'),
        startTime: Yup.string().required('Start Time is required'),
        endTime: Yup.string().required('End Time is required'),
        status: Yup.number(),
    });

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            title: eventData?.title ? eventData?.title : '',
            link: eventData?.link ? eventData?.link : '',
            userModeration: eventData?.userModeration ? eventData?.userModeration : UserModeration.DISABLED,
            timezone: currentTimezone?.name,
            startDate: eventData?.eventStartDateTime ? moment.unix(Number(eventData?.eventStartDateTime)).format('DD/MM/YYYY') : moment().format('DD/MM/YYYY'),
            endDate: eventData?.eventEndDateTime ? moment.unix(Number(eventData?.eventEndDateTime)).format('DD/MM/YYYY') : moment().add(1, 'days').format('DD/MM/YYYY'),
            startTime: eventData?.eventStartDateTime ? moment.unix(Number(eventData?.eventStartDateTime)).format('HH:mm') : moment().add(10 - (moment().minute() % 10), 'minutes').format('HH:mm'),
            endTime: eventData?.eventEndDateTime ? moment.unix(Number(eventData?.eventEndDateTime)).format('HH:mm') : moment().add(10 - (moment().minute() % 10), 'minutes').format('HH:mm'),
            status: eventData?.status ? eventData?.status : 0,
        },
        onSubmit: (): void => 
        {          
            const { title, link, userModeration } = formik.values;

            const eventStartDateTime = combineDateTime(formik.values.startDate, formik.values.startTime);
            const eventEndDateTime = combineDateTime(formik.values.endDate, formik.values.endTime);

            if (!(_.isEmpty((eventData)))) 
            {
                const isEventStartDateTimeChanged = moment.unix(eventData.eventStartDateTime).format('YYYY-MM-DD HH:mm') !== moment.unix(Number(eventStartDateTime)).format('YYYY-MM-DD HH:mm');
                const isEventEndDateTimeChanged = moment.unix(eventData.eventEndDateTime).format('YYYY-MM-DD HH:mm') !== moment.unix(Number(eventEndDateTime)).format('YYYY-MM-DD HH:mm');
                const isEventDataChanged = eventData.title !== title || eventData.link !== link || eventData.timezone !== eventReduxData?.timeZone || eventData.userModeration !== userModeration;
                
                if (!(_.isEmpty((eventData))) && (isEventStartDateTimeChanged || isEventEndDateTimeChanged || isEventDataChanged)) 
                {
                    updateEventDetails();
                }
                else 
                {
                    return;
                }
            }
            else 
            {
                addEventDetails();
            }
        }
    });

    const updateEventDetails = async (): Promise<void> => 
    {
        const eventStartDateTime = combineDateTime(formik.values.startDate, formik.values.startTime);
        const eventEndDateTime = combineDateTime(formik.values.endDate, formik.values.endTime);

        if (Number(eventEndDateTime) < Number(eventStartDateTime)) 
        {
            toast.error('Event End Timing should be Greater than Event Start Timing');            
            return;
        }
        setSpinner(true);

        const eventDetails = {
            title: formik.values.title,
            link: formik.values.link,
            userModeration: formik.values.userModeration,
            timezone: eventReduxData.timeZone,
            eventStartDateTime,
            eventEndDateTime,
        };
        try {
            const eventsDataUpdated = await updateEvent(String(eventData?.id), eventDetails);
            if (!eventsDataUpdated) return;

            if (eventsDataUpdated) {
                dispatch(addEvent({
                    eventStartDateTime: eventsDataUpdated.eventStartDateTime,
                    eventEndDateTime: eventsDataUpdated.eventEndDateTime,
                }));
            }
        
            if (!props.fromOverview) {
                props?.setRefresh(true);
            }

            toast.success('Event details updated successfully');
        
            eventBus.dispatch('event-overview-refreshed', { refresh: true });
            eventBus.dispatch('event-details-refreshed', {
                refresh: true 
            });
            eventBus.dispatch('Refresh-events-top-bar', { refresh: true });
        
            handleDrawerClose();
        } catch (error) {
            console.log(error);
            toast.error((error as Error)?.message || 'Error updating event details');
        } finally {
            setSpinner(false);
        }
    };

    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
        {
            const value = event.target.value;
            const newLink = transformTitleToLink(value);
            formik.setFieldValue('title', value);
            formik.setFieldValue('link', newLink);
            dispatch(addEvent({
                title: value,
                link: newLink,
            }));
        };
    

    const addEventDetails = async () : Promise<void> => 
    {
        setSpinner(true);

        const eventStartDateTime = combineDateTime(formik.values.startDate, formik.values.startTime);
        const eventEndDateTime = combineDateTime(formik.values.endDate, formik.values.endTime);

        if (Number(eventEndDateTime) < Number(eventStartDateTime)) 
        {
            toast.error('Event End Timing should be Greater than Event Start Timing');
            setSpinner(false);
            return;
        }
        
        const eventDetails = {
            title: formik.values.title,
            link: formik.values.link,
            timezone: eventReduxData.timeZone,
            userModeration: formik.values.userModeration,
            eventStartDateTime,
            eventEndDateTime,
            status: 1,
        };

        try {
            const eventCreated = await createEvent(eventDetails, csrfTokenData);
            if (eventCreated) {
                if (!(eventCreated instanceof Error)) {
                    dispatch(addEvent({
                        eventStartDate: moment.unix(eventCreated.eventStartDateTime).format('YYYY-MM-DD'),
                        eventStartTime: moment.unix(eventCreated.eventStartDateTime).format('HH:mm'),
                        eventEndDate: moment.unix(eventCreated.eventEndDateTime).format('YYYY-MM-DD'),
                        eventEndTime: moment.unix(eventCreated.eventEndDateTime).format('HH:mm'),
                        eventStartDateTime: eventCreated.eventStartDateTime,
                        eventEndDateTime: eventCreated.eventEndDateTime,
                        shortName: eventCreated.link,
                    }));
    
                    setSpinner(false);
                    handleDrawerClose();
                    props?.setRefresh(true);
    
                    toast.success('Event Created');
    
                    navigate('/events/' + Number(eventCreated.id) + '/photo-sharing');
                }
            }
        } catch (error) {
            console.log(error);
            toast.error((error as Error)?.message || 'Error creating event');
        } finally {
            setSpinner(false);
        }
    };

    const fetchData = async (): Promise<void> => 
    {
        eventBus.on(APP_CONSTANTS.EVENTS.SOFT_RELOAD, ():void => 
        {
            navigate(0);
        });

        eventBus.on(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_BUTTON, (data):void => 
        {
            setSpinner(data.spinner);
        });

        if (!(_.isEmpty((eventData)))) 
        {
            dispatch(addEvent({
                title: eventData.title,
                description: eventData.description,
                eventStartDate: moment.unix(eventData.eventStartDateTime).format('YYYY-MM-DD'),
                eventStartTime: moment.unix(eventData.eventStartDateTime).format('HH:mm'),
                eventEndDate: moment.unix(eventData.eventEndDateTime).format('YYYY-MM-DD'),
                eventEndTime: moment.unix(eventData.eventEndDateTime).format('HH:mm'),
                timeZone: eventData.timezone,
                userModeration: eventData.userModeration,
                link: eventData.link,
                status: eventData.status ? eventData.status : 1,
                eventStartDateTime: eventData.eventStartDateTime,
                eventEndDateTime: eventData.eventEndDateTime,
            }));
            // let words = eventData.description.split(" ");
            // let wordsLength = words.length;
            setEventTitleLength(eventData?.title?.length);

            formik.setFieldValue('eventMapId', eventData?.lumaEventId ? eventData?.lumaEventId : '');

            const defaultTimezone = _.filter(uniqueTimezoneArr, function (elem: {name: string; value: string}) : boolean
            {
                return elem.value === eventData.timezone;
            });

            if (defaultTimezone) 
            {
                dispatch(addEvent({
                    timezoneDisplayName: defaultTimezone[0]?.name ? defaultTimezone[0]?.name : ''
                }));
                formik.setFieldValue('timezone', defaultTimezone[0]?.name ? defaultTimezone[0]?.name : '');
            }

        }
    };

    useEffect((): void => 
    {
        fetchData();
        if (currentTimezone)
        {
            dispatch(addEvent({
                timezoneDisplayName: currentTimezone?.name,
                timeZone: currentTimezone?.value,
            }));
        }
    }, []);

    return (
        <Box id="createEvent">
            <CloseIconComponent onClick={handleDrawerClose} />
            <Form noValidate autoComplete="off" onSubmit={(values: React.FormEvent<HTMLFormElement>): void => 
            {
                return formik.handleSubmit(values);
            }}>
                {/* Event Name */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={'Event Name'} required={true} />
                    <FormControlComponent
                        value={formik.values.title}
                        placeholder={'Name'}
                        type={'text'}
                        onChange={handleTitleChange}
                        isInvalid={formik.errors.title && formik.touched.title}
                    />
                    <Box className="title-length-error-cont">
                        {formik.errors.title && formik.touched.title ? <Typography className="error-msg">{formik.errors.title}</Typography> : <div/>}
                        <Typography className="title-length">{eventTitleLength}/100</Typography>
                    </Box>
                </Box>

                {/* Event Timing & Timezone */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={'Select Date & Time'} required={true} />
                    <Box className="event-timing-wrapper">
                        <div className="event-timing-container">
                            <div className="start-timing">
                                <DateTimePicker 
                                    dateValue={moment(formik.values.startDate, 'DD/MM/YYYY').unix()}
                                    timeValue={formik.values.startTime}
                                    onDateChange={(newValue): void => {
                                        formik.setFieldValue('startDate', newValue);
                                    }}
                                    onTimeChange={(newValue): void => { 
                                        formik.setFieldValue('startTime', newValue);
                                    }}
                                    dateFormat='ddd, MMM D'
                                    showYearDropdown
                                />
                            </div>
                            <p>-</p>
                            <div className="end-timing">
                                <DateTimePicker 
                                    dateValue={moment(formik.values.endDate, 'DD/MM/YYYY').unix()}
                                    timeValue={formik.values.endTime}
                                    onDateChange={(newValue): void => {
                                        formik.setFieldValue('endDate', newValue);
                                    }}
                                    onTimeChange={(newValue): void => { 
                                        formik.setFieldValue('endTime', newValue);
                                    }}
                                    dateFormat='ddd, MMM D'
                                    minDate={moment(formik.values.startDate, 'DD/MM/YYYY').unix()}
                                    maxDate={moment(formik.values.startDate, 'DD/MM/YYYY').add(30, 'days').unix()}
                                    showYearDropdown
                                />
                            </div>
                        </div>

                        <Box className="event-timezone-component">
                            <Box className="w-100">
                                <TimezoneSelectComponent defaultValue={formik.values?.timezone} value={formik.values?.timezone} optionsArr={uniqueTimezoneArr} onChange={handleTimeZoneChange} placeholder='Select a timezone' />
                                {formik.errors.timezone && formik.touched.timezone ? <Typography className="error-msg">{formik.errors.timezone}</Typography> : null}
                            </Box>
                        </Box>
                    </Box>
                </Box>
                
                <div>
                    {/* Public URL */}
                    <Box className="sidebar-container-spacing">
                        <Box className="tooltip-box">
                            <FormLabelComponent label={'Public URL'} required />
                            <Tooltip
                                title="Host your event landing page in this URL"
                                arrow
                                placement="top"
                            >
                                <FontAwesomeIcon
                                    icon={['fal', 'info-circle']}
                                    className="info-icon"
                                />
                            </Tooltip>
                        </Box>
                        <FormControlComponent
                            type={'text'}
                            value={formik.values.link}
                            placeholder='Public URL'
                            onChange={(event): void => 
                            {
                                formik.setFieldValue('link', event.target.value);
                                dispatch(addEvent({
                                    link: event.target.value
                                }));
                            }}
                            isInvalid={formik.errors.link && formik.touched.link}
                        />
                        <Typography className="short-link-example">{APP_CONSTANTS.CONSUMER_APP_URL}/{eventReduxData.link}</Typography>
                        {formik.errors.link && formik.touched.link ? <Typography className="error-msg">{formik.errors.link}</Typography> : null}
                    </Box>

                    {/* Require Approval */}
                    <Box className="sidebar-container-spacing">
                        <Box className="approval-container">
                            <Box className="approval-icon-label-container">
                                <FontAwesomeIcon icon={['fal', 'user-check']} className="approval-icon" />
                                <FormLabelComponent label={'User Upload Moderation'} noBottomMargin />
                            </Box>
                            <FormControlLabel
                                checked={formik.values.userModeration === UserModeration.ENABLED}
                                control={<CustomSwitch
                                    onChange={(event:  React.ChangeEvent<HTMLInputElement>): void => 
                                    {
                                        formik.setFieldValue('userModeration', event.target.checked ? UserModeration.ENABLED : UserModeration.DISABLED);
                                        dispatch(addEvent({
                                            userModeration: event.target.checked ? UserModeration.ENABLED : UserModeration.DISABLED
                                        }));
                                    }}
                                />}
                                label={''}
                            />
                        </Box>
                    </Box>
                </div>

                <Box width={'500px'} className="submit-btn-container">
                    <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-end'}>
                        <CustomButton onClick={handleDrawerClose} btnType={'secondary'} name={CONTENT.SIDE_DRAWER.CLOSE_BTN} />
                        <CustomButton type="submit" loading={spinner} name={!(_.isEmpty(eventData)) ? 'Update' : 'Create'} btnType={'primary'} />
                    </Stack>
                </Box>

            </Form >
        </Box >
    );
};

export default CreateEvent;